// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message_IeyrB{display:flex;padding:12px 0;word-break:break-word}.message__text_Gxzsr{flex:1;padding:16px;color:var(--TextDefault);background:var(--Layer1);border-radius:0 20px 20px}.message__text_Gxzsr,.message__text--system_LSZxa{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px}.message__text--system_LSZxa{padding:12px 0;margin:0 auto;color:var(--TextSecondary);text-align:center;background:#0000}.message--client_IqBCB{flex-direction:row-reverse}.message--client_IqBCB .message__text_Gxzsr{color:var(--BrandText);background:var(--Blue);border-radius:20px 20px 0}.message--system_S2ML5 .message__text_Gxzsr{color:var(--TextPrimary);background:var(--OpacityLayer1)}.message__info_jcErD{margin-right:8px;vertical-align:top;fill:var(--DBrandDefault)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message": `message_IeyrB`,
	"message__text": `message__text_Gxzsr`,
	"message__text--system": `message__text--system_LSZxa`,
	"message--client": `message--client_IqBCB`,
	"message--system": `message--system_S2ML5`,
	"message__info": `message__info_jcErD`
};
export default ___CSS_LOADER_EXPORT___;
