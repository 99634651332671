// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.images_EaS2E{position:absolute;top:0;right:0;bottom:0;left:0;display:flex;flex-direction:column;background:var(--Layer0);border-radius:inherit}.images-content_mwEEz{flex:1;width:100%;height:1px}.images-content__image_L76_n{max-width:100%;max-height:100%;object-fit:contain;-webkit-user-drag:none}.images-footer_LMJYL{width:100%;padding:16px 0}.images-footer__image_AVZsQ{cursor:pointer;opacity:.6}.images-footer__image--active_cRA3R,.images-footer__image_AVZsQ:hover{opacity:1}.images-footer__swiper-slide_hivk1 .images-footer__swiper-content_cWL_Q{padding:0 6px}.images-footer__swiper-slide_hivk1:first-child .images-footer__swiper-content_cWL_Q{padding-left:16px}.images-footer__swiper-slide_hivk1:last-child .images-footer__swiper-content_cWL_Q{padding-right:16px}.images__swiper_wkCWU{height:100%}.images__swiper-slide_OMdUD{display:flex;align-items:center;justify-content:center;width:100%}.images__file_s35qy{width:100%;height:100%;padding:48px 0}.images__file-inner_dgsnv{font-size:12px;font-weight:500;line-height:16px;letter-spacing:normal;display:flex;flex-direction:column;align-items:center;justify-content:center;height:100%;padding:0 58px;color:var(--TextDefault);background:var(--Layer1);border-radius:10px}html.app__layout--desktop .images__file-inner_dgsnv{cursor:pointer}html.app__layout--desktop .images__file-inner_dgsnv:hover{background:var(--Highlight)}.images__file-icon_Vrfbh{margin-bottom:70px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"images": `images_EaS2E`,
	"images-content": `images-content_mwEEz`,
	"images-content__image": `images-content__image_L76_n`,
	"images-footer": `images-footer_LMJYL`,
	"images-footer__image": `images-footer__image_AVZsQ`,
	"images-footer__image--active": `images-footer__image--active_cRA3R`,
	"images-footer__swiper-slide": `images-footer__swiper-slide_hivk1`,
	"images-footer__swiper-content": `images-footer__swiper-content_cWL_Q`,
	"images__swiper": `images__swiper_wkCWU`,
	"images__swiper-slide": `images__swiper-slide_OMdUD`,
	"images__file": `images__file_s35qy`,
	"images__file-inner": `images__file-inner_dgsnv`,
	"images__file-icon": `images__file-icon_Vrfbh`
};
export default ___CSS_LOADER_EXPORT___;
