// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.conversation__rjRF{position:relative;height:100%;padding:0 16px}.conversation__actions_V78mS{display:flex;flex-flow:row wrap;justify-content:flex-end;width:100%;max-width:306px;padding:12px 0;margin-left:auto}.conversation__action_uZCgX{margin-bottom:12px;margin-left:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"conversation": `conversation__rjRF`,
	"conversation__actions": `conversation__actions_V78mS`,
	"conversation__action": `conversation__action_uZCgX`
};
export default ___CSS_LOADER_EXPORT___;
