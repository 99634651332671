// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-chat_nVw3B{position:relative;display:flex;flex-direction:column;flex-wrap:nowrap;background-color:var(--Layer0)}.live-chat__body_PlAcv{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live-chat": `live-chat_nVw3B`,
	"live-chat__body": `live-chat__body_PlAcv`
};
export default ___CSS_LOADER_EXPORT___;
