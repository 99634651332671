// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.live-chat-body_h6r30{position:relative;overflow:auto}.live-chat-body__inner_vu7cx{position:absolute;left:0;width:100%;max-height:100%}.live-chat-body__inner--bottom_jMGDR{bottom:0}.live-chat-body__inner_vu7cx:not(.live-chat-body__inner--bottom_jMGDR){top:0}.live-chat-body__loading_q25AO{text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"live-chat-body": `live-chat-body_h6r30`,
	"live-chat-body__inner": `live-chat-body__inner_vu7cx`,
	"live-chat-body__inner--bottom": `live-chat-body__inner--bottom_jMGDR`,
	"live-chat-body__loading": `live-chat-body__loading_q25AO`
};
export default ___CSS_LOADER_EXPORT___;
