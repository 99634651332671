// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-action_Y37SK{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:flex;padding:16px;color:var(--TextDefault);cursor:pointer;border:1px solid var(--Blue);border-radius:20px 20px 0}.chat-action__icon_rIX7Z{display:flex;width:20px;height:20px;color:var(--Blue)}.chat-action--with-title_lgoR_ .chat-action__icon_rIX7Z{margin-right:8px}.chat-action__icon_rIX7Z span{width:100%;height:100%;font-size:20px}.chat-action_Y37SK:hover{border:1px solid var(--BlueHighlight)}.chat-action_Y37SK:hover,.chat-action_Y37SK:hover .chat-action__icon_rIX7Z{color:var(--BlueHighlight)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chat-action": `chat-action_Y37SK`,
	"chat-action__icon": `chat-action__icon_rIX7Z`,
	"chat-action--with-title": `chat-action--with-title_lgoR_`
};
export default ___CSS_LOADER_EXPORT___;
