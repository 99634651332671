// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files-message_oy7ej{display:flex;margin-top:24px}.files-message__files_nbVOp{display:flex;flex:1;flex-wrap:wrap}.files-message__file_jfrM3{margin-bottom:12px;margin-left:12px}.files-message--client_lKPgr,.files-message--client_lKPgr .files-message__files_nbVOp{flex-direction:row-reverse}.files-message__time_hO4xV{margin-bottom:12px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"files-message": `files-message_oy7ej`,
	"files-message__files": `files-message__files_nbVOp`,
	"files-message__file": `files-message__file_jfrM3`,
	"files-message--client": `files-message--client_lKPgr`,
	"files-message__time": `files-message__time_hO4xV`
};
export default ___CSS_LOADER_EXPORT___;
