// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message-time_PbHmJ{align-self:flex-end;width:56px;min-width:56px;padding:0 4px;color:var(--TextSecondary);text-align:right;font-size:12px;font-weight:400;line-height:16px;letter-spacing:normal}.message-time--client_u5vFd{text-align:left}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"message-time": `message-time_PbHmJ`,
	"message-time--client": `message-time--client_u5vFd`
};
export default ___CSS_LOADER_EXPORT___;
