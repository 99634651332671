// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.survey_uKSLw{display:flex;flex-direction:column;gap:16px;width:287px;padding:16px;background:var(--OpacityLayer1);border-radius:0 20px 20px}.survey__container_vXWvv{display:flex;flex:1;padding:12px 0}.survey__text_J_YZM{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin:0;color:var(--TextPrimary);word-break:break-word}.survey__info_hpRhP{margin-right:8px;vertical-align:top}.survey--confirm_hufVN .survey__star_NM6WZ{cursor:default}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"survey": `survey_uKSLw`,
	"survey__container": `survey__container_vXWvv`,
	"survey__text": `survey__text_J_YZM`,
	"survey__info": `survey__info_hpRhP`,
	"survey--confirm": `survey--confirm_hufVN`,
	"survey__star": `survey__star_NM6WZ`
};
export default ___CSS_LOADER_EXPORT___;
