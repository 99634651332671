// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.busy-form_pa9Xt{padding:16px 8px}.busy-form__title_YRcco{margin-bottom:8px;color:var(--TextDefault);text-align:center;font-size:18px;font-weight:500;line-height:28px;letter-spacing:normal}.busy-form__description_oPmgH{margin-bottom:20px;color:var(--TextPrimary);text-align:center;font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"busy-form": `busy-form_pa9Xt`,
	"busy-form__title": `busy-form__title_YRcco`,
	"busy-form__description": `busy-form__description_oPmgH`
};
export default ___CSS_LOADER_EXPORT___;
